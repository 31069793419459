/**
 * Fastclick
 */
window.addEventListener('load', function() {
	FastClick.attach(document.body);
}, false);

/**
 * jQuery stuff
 */
$(document).ready(function() {

	/**
	 * Test si les Media Queries sont supportées
	 * Ajoute la classe 'mq' au tag HTML si c'est le cas
	 */
	if ( Modernizr.mq('only all') ) {
		jQuery('html').addClass('mq');
	} else {
		jQuery('html').addClass('no-mq');
	}

	isTouch = Modernizr.touch;

	/**
	 * Browser detect : masquage de la bannière
	 */
	$('.browserdetect__close').click(function(e) {
		e.preventDefault();
		$('.browserdetect').addClass('browserdetect--hidden');
	});

	/**
	 * MatchHeight [GRID]
	 */
	$(".harmonize").matchHeight();

	/**
	 * Open formulaire de connexion
	 */
	$('a.prescripteur-acces').click(function(e) {
		if(!$(this).hasClass("connected")) {
			e.preventDefault();
			$("#prescripteur-acces").slideToggle();
		}
	});

	$("#fLogin").submit(function(){

        if($("#fLogin").validationEngine('validate')){

            var data = $(this).serialize() + '&act=login';
            var div  = $(this).find('.retour').empty().html('envoi en cours...');

            $.ajax({
                type    : "POST",
                data    : data,
                url     : "/login/",
                success : function(retour) {
                	try {
				        var data = JSON.parse(retour);
				    	if( data.error ) div.html(data.error);
				    	if( data.redirect ) window.location = data.redirect;
				    } catch (e) {
				        return false;
				    }
                }
            });
        }
        return false;
    });

	// MENU PRINCIPAL DU SITE - PLUGIN RESPONSIVE NAV //
	/*var nav = responsiveNav(".contain-menu ul", { // Selector
		animate: true, // Boolean: Use CSS3 transitions, true or false
		transition: 284, // Integer: Speed of the transition, in milliseconds
		label: "Menu", // String: Label for the navigation toggle
		insert: "after"
	});*/
	
	// SLIDER PAGE ACCUEIL - PLUGIN FOTORAMA  //
	/*$('.slider').fotorama({
		width:               "100%",
		minheight:           "200",
		fit:                 "cover",
		nav:                 false,
		loop:                true,
		autoplay:            4000,
		arrows:              true,
		swipe:               true,
		stopautoplayontouch: false
	});*/

	// SLIDER PAGES INTÉRIEURES - PLUGIN OWL CAROUSEL //
	/*$('.slider-page-int').owlCarousel({
		dots:false,
		loop:true,
		navText:["",""],
		// autoWidth:true,
		responsiveClass:true,
		responsive:{
		    0:{
		        items:1,
		        nav:false
		    },
		    480:{
		        items:2,
		        nav:false
		    },
		    1000:{
		        items:3,
		        nav:true
		    }
		}
	});*/
	
	//  GOOGLE MAP POUR PROGRAMMES //
	if($("#map").length > 0) {

		var draggable = false;
		var $lat      = $("#map-lat");
		var $lng      = $("#map-lng");
		var $zoom     = $("#map-zoom");

		var map = new GMaps({
			zoomControlOpt: {
            style : 'SMALL',
            position: 'TOP_RIGHT'
        },

			options: {
				'styles': 

				[
				  {
				    "featureType": "administrative",
				    "stylers": [
				      { "saturation": -100 }
				    ]
				  },{
				    "featureType": "landscape",
				    "stylers": [
				      { "saturation": -100 }
				    ]
				  },{
				    "featureType": "poi",
				    "stylers": [
				      { "saturation": -100 }
				    ]
				  },{
				    "featureType": "road.local",
				    "stylers": [
				      { "saturation": -100 }
				    ]
				  },{
				    "featureType": "road.highway",
				    "stylers": [
				      { "invert_lightness": true },
				      { "hue": "#293581" },
				      { "lightness": 39 },
				      { "saturation": -84 }
				    ]
				  },{
				    "featureType": "road.arterial",
				    "stylers": [
				      { "lightness": 50 },
				      { "hue": "#293581" },
				      { "saturation": -87 }
				    ]
				  },{
				    "featureType": "transit",
				    "stylers": [
				      { "hue": "#293581" }
				    ]
					  },{
				    "featureType": "water",
				    "stylers": [
				      { "color": "#7da7d9" }
				    ]
				  },{
				  }
				]

			},


			div: '#map',
			lat: $lat.val(),
			lng: $lng.val(),
			zoom: parseInt($zoom.val()),
			scrollwheel: false,
			zoom_changed: function() {
				$zoom.val(this.getZoom());
			}
		});

		var marker = map.addMarker({
			lat: $lat.val(),
			lng: $lng.val(),
			icon: '/img/bulle.png',
			draggable: false,
			position_changed: function() {
				$lat.val(this.getPosition().lat());
				$lng.val(this.getPosition().lng());
			}
		});
	}

	//  GOOGLE MAP POUR ESPACE PRESCRIPTEUR //
	if($("#map-presc").length > 0) {

		var div       = $("#map-presc");
		var draggable = false;
		var $lat      = div.data("lat");
		var $lng      = div.data("lng");
		var $zoom     = div.data("zoom");

		$.fn.matchHeight._afterUpdate = function(event, groups) {
			var map = new GMaps({
				zoomControlOpt: {
	            style : 'SMALL',
	            position: 'TOP_RIGHT'
	        },

				options: {
				'styles': 

				[
				  {
				    "featureType": "administrative",
				    "stylers": [
				      { "saturation": -100 }
				    ]
				  },{
				    "featureType": "landscape",
				    "stylers": [
				      { "saturation": -100 }
				    ]
				  },{
				    "featureType": "poi",
				    "stylers": [
				      { "saturation": -100 }
				    ]
				  },{
				    "featureType": "road.local",
				    "stylers": [
				      { "saturation": -100 }
				    ]
				  },{
				    "featureType": "road.highway",
				    "stylers": [
				      { "invert_lightness": true },
				      { "hue": "#293581" },
				      { "lightness": 39 },
				      { "saturation": -84 }
				    ]
				  },{
				    "featureType": "road.arterial",
				    "stylers": [
				      { "lightness": 50 },
				      { "hue": "#293581" },
				      { "saturation": -87 }
				    ]
				  },{
				    "featureType": "transit",
				    "stylers": [
				      { "hue": "#293581" }
				    ]
					  },{
				    "featureType": "water",
				    "stylers": [
				      { "color": "#7da7d9" }
				    ]
				  },{
				  }
				]

			},

				div: '#map-presc',
				lat: $lat,
				lng: $lng,
				zoom: parseInt($zoom),
				scrollwheel: false,
				zoom_changed: function() {
					$zoom.val(this.getZoom());
				}
			});

			var marker = map.addMarker({
				lat: $lat,
				lng: $lng,
				icon: '/img/bulle.png',
				draggable: false,
				position_changed: function() {
					$lat.val(this.getPosition().lat());
					$lng.val(this.getPosition().lng());
				}
			});
		};

		
	}
   
   // FORMULAIRE CONTACT - VALIDATION ENGINE //
    $("form").validationEngine({
        scroll      : false
    });

    $("#contact").submit(function(){

        if($("#contact").validationEngine('validate')){

            var data = $(this).serialize() + '&act=envoi';
            var div  = $(this).empty().html('<div class="form-return">envoi en cours...</div>');
            var url  = $(this).attr("action");

            $.ajax({
                type    : "POST",
                data    : data,
                url     : url,
                success : function(retour) {
                    div.empty().append(retour);
                }
            });
        }
        return false;
    });

    $(".md-trigger").click(function(e){
    	e.preventDefault();
    	$("#fOption input, #fOption textarea").val('');
    	$("#fOption input[name='~lot']").val( $(this).data('lot') );
    	$(".presc--modal__number").html( $(this).data('num') );
    });

    $("#fOption").submit(function(){

        if($("#fOption").validationEngine('validate')){

            var data      = $(this).serialize() + '&act=envoi';
            var div       = $(this).hide();
            var divRetour = $(".retour").show().html('<div class="form-return">envoi en cours...</div>');

            $.ajax({
                type    : "POST",
                data    : data,
                url     : '/option/',
                success : function(retour) {
                    divRetour.empty().append(retour);
                    setTimeout(function(){ $('.md-close').trigger('click'); }, 2000);
                }
            });
        }
        return false;
    });

    $("#fDenonciation").submit(function(){

        if($("#fDenonciation").validationEngine('validate')){

            var data      = $(this).serialize() + '&act=denonciation';
            var div       = $(this).hide();
            var divRetour = $(".retour").show().html('<div class="form-return">envoi en cours...</div>');

            $.ajax({
                type    : "POST",
                data    : data,
                url     : '/denonciation/',
                success : function(retour) {
                    divRetour.empty().append(retour);
                    setTimeout(function(){ $('.md-close').trigger('click'); }, 2000);
                }
            });
        }
        return false;
    });

    $(".md-close").click(function(){
    	$("#fOption").show();
    	$(".retour").hide();
    });

    // Data-link cellule tableau en mobile //
    if( isTouch ){
		$('[data-link]').click(function() {
			window.location.href = $(this).attr('data-link');
		}).find('button').click(function(e) {
			e.stopPropagation();
		});
	}

	if($('.prog-descr span').hasClass('renaissance_txt')){
		$(".renaissance_resume").show();
	}
});